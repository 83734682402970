.content-inner > * {
  margin-bottom: 16px;
}

.pagetitle {
  margin-bottom: 40px;
  text-align: center;
}

.destress {
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
}

.center {
  text-align: center;
}

.feedback-input {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Raleway', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: lighten(#E9EDC9, 3%);
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #606c38;
  transition: all 0.3s;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
}

.feedback-input:focus {
  border: 1px solid lighten(#606c38, 15%);
}

textarea {
  height: 150px;
  line-height: 150%;
  resize: vertical;
  font-family: 'Raleway', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

[type='submit'] {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  border-radius: 8px;
  padding: 14px 24px 16px;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  transition: transform 200ms, background 200ms;
  background-color: lighten(#E9EDC9, 3%);
  box-shadow: 0 0 0 1px #606c38 inset;
  color: #263238;
  &:hover {
    transform: translateY(-2px);
    color: #263238;
    box-shadow: 0 0 0 1px lighten(#606c38, 15%) inset;
  }
  width: 100%;
}

.service-card {
  border-radius: 8px;
  border: 1px solid #606c38;
  background-color: #e6f4f5;

  & .service-card__header {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: flex-start;
    border-bottom: 1px solid #606c38;
    padding: 20px;
    background: #c6d596;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  & .service-card__title {
    & h4 {
      font-size: 1.1em;
      font-weight: 700;
      margin-bottom: 2px;
    }
    & h5 {
      font-size: 0.8em;
      font-weight: 500;
      opacity: 0.8;
    }
  }

  & .service-card__price {
    & > h3 {
      font-weight: 500;
    }
    font-size: 1.5em;
  }

  & .service-card__body {
    padding: 20px;
  }

  & .service-card__desc {
    font-size: 0.9em;
  }
}

.service-card-connected-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .service-card-connected-container__header{
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: flex-end;
    padding: 10px;
    width: 100%;
    flex: 1;
  }

  & .service-card-connected-container__title {
    font-weight: 600;
  }

  & .service-card-connected-container__price {
    text-align: right;
    font-weight: 800;
    font-size: 1.1em;
  }

  & .service-card-connected-container__connector {
    width: 1px;
    height: 20px;
    background-color: #606c38;
  }

  & .service-card {
    width: 100%;
    flex: 1;
  }
}


@media (max-width: 375px) {
  .service-card-connected-container { 
    & .service-card-connected-container__header{
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.service {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px;
  border-radius: 8px;
  border: 2px solid darken(#E9EDC9, 5%);
  background: lighten(#a8dadc, 17%);
  position: relative;

  & .service:not(:last-child) {
    margin-bottom: 12px;
  }

  & .service-title {
    text-align: left;
    font-weight: 700;
    font-size: 1.3em;
  }

  & .service-desc {
    margin-top: 8px;
  }
  
  & .spacer {
    margin-top: 8px;
  }

  & .service-price {
    position: absolute;
    top: 8px;
    right: 8px;
    text-align: right;
    font-size: 0.9em;
    font-style: italic;
  }
}

@media (max-width: 380px) {
  .service {
    & .service-title {
      padding-top: 18px;
      max-width: 100%;
    }

    & .service-price {
      top: 8px;
      left: 16px;
      right: unset;
    }
  }
}


.quote {
  font-family: 'Edu AU VIC WA NT Guides', cursive;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: lighten(#E9EDC9, 3%);
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #606c38;
}


@media (max-width: 462px) {
  .quote h3 {
    font-size: 1em;
  }
}


.section {
  background-color: lighten(#E9EDC9, 3%);
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #606c38;
}

.meet-lisa-portrait {
  height: 345px;
  float: right;
  margin: 12px;
}

@media only screen and (max-width: 600px) {
  .meet-lisa-portrait-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    margin-top: -16px;
  }
  .meet-lisa-portrait {
    float: none;
  }
}

.card-container {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  width: 100%;
  max-width: 150px;
  background: lighten(#a8dadc, 17%);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  text-align: right;
  min-height: 57px;
  border: 2px solid darken(#E9EDC9, 5%);

  & .card-title {
    text-align: right;
    font-weight: 600;
    flex: 1;
  }

  &.random-image {
    background-size: cover;
    background-position: 0 140px;
  }
}

.integrated-health {
  margin-bottom: 12px;
}

@media only screen and (max-width: 675px) {
  .card-container {
    gap: 8px;
  }
  .card {
    padding: 12px;
  }
  .integrated-health, .service-title {
    max-width: 60%;
  }
}

@media only screen and (max-width: 410px) {
  .card-container {
    gap: 4px;
  }
  .card {
    padding: 8px;
    max-width: 100%;
  }
}

.question {
  font-size: 1.2em;
}