* {
  padding: 0;
  margin: 0;
  --navbar-blue: #a8dadc;
  --body-bg: #e9edc9;
}

@import url('https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: 'Raleway', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: darken(#e9edc9, 5%);
  color: #263238;
}

p {
  line-height: 1.8em;
}

#root {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.header {
  background-position: center;
  background-size: cover;
  height: 225px;
  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
}

.floating-portrait {
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  display: flex;
  align-items: center;

  & img {
    height: 215px;
    margin: auto;
  }
}

@media only screen and (max-width: 465px) {
  .floating-portrait {
    align-items: flex-end;
    justify-content: flex-end;
    right: 8px;
    & img {
      height: 150px;
      margin: unset;
      margin-bottom: 8px;
    }
  }

  .header .logo {
    margin-bottom: 8px;
    margin-left: 8px;
  }
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background: var(--navbar-blue);
  border-top: 1px solid darken(#a8dadc, 25%);
  border-bottom: 1px solid darken(#a8dadc, 25%);
}

nav h1 {
  font-weight: 800;
}

nav ul {
  display: flex;
  align-items: center;
  list-style: none;
}

nav li {
  padding-left: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.logo-and-title {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: unset;
  &:hover {
    color: unset;
  }

  & .logo {
    height: 100px;
    width: 100px;
    aspect-ratio: 1/1;
  }
}

a {
  text-decoration: none;
  color: #0d47a1;
}

a.active,
a:hover {
  color: #5599f1;
}

.nav-toggle {
  display: none;
}

/* 
    Extra small devices (phones, 600px and down) 
  */
@media only screen and (max-width: 675px) {
  nav {
    flex-direction: column;
  }
  nav ul {
    flex-direction: column;
    padding-top: 0.5rem;
    overflow: hidden;
  }
  nav li {
    padding: 0.5rem 0;
  }

  .nav-toggle {
    display: unset;
    width: fit-content !important;
    padding: 13px 20px;
    background-color: lighten(#a8dadc, 5%);
    position: relative;
    margin-top: 5px;
  }

  .mobile-nav-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  nav ul.closed {
    max-height: 0px;
    opacity: 0;
    padding-top: 0;
    pointer-events: none;
    transition: all 0.4s ease-in-out;
  }

  nav ul.open {
    max-height: 200px;
    padding-top: 0.5rem;
    opacity: 1;
    transition: all 0.4s ease-in-out;
  }
}

@media (max-width: 400px) {
  nav {
    padding: 0.5rem
  }
}

.content {
  padding: 24px;
  background-color: var(--body-bg);
  height: 100%;
}

.content-inner {
  max-width: 720px;
  margin: 0 auto;
  height: 100%;
}

button {
  appearance: button;
  background-color: #a8dadc;
  background-image: none;
  border: 1px solid #a8dadc;
  border-radius: 4px;
  box-shadow: #263238 4px 4px 0 0, #a8dadc 4px 4px 0 1px;
  box-sizing: border-box;
  color: #263238;
  cursor: pointer;
  display: inline-block;
  font-family: ITCAvantGardeStd-Bk, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 12px 40px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  margin-top: 16px;

  &:focus {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  &:active {
    box-shadow: rgba(0, 0, 0, 0.125) 0 3px 5px inset;
    outline: 0;
  }

  &:not([disabled]):active {
    box-shadow: #263238 2px 2px 0 0, #a8dadc 2px 2px 0 1px;
    transform: translate(2px, 2px);
  }
}

@media (min-width: 768px) {
  button {
    padding: 12px 50px;
  }
}

img {
  max-width: 100%;
  border-radius: 8px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  flex: 1;
  background-color: #283618;
  padding: 12px;
  padding-right: 24px;
  color: #a8dadc;
}

.certified {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  & img {
    width: 120px;
  }
  & a {
    color: #a8dadc;
    &:hover {
      color: #5599f1;
    }
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

.footer-accent {
  background-color: #606c38;
  height: 30px;
  width: 100%;
}

@media (max-width: 603px) {
  .footer-content {
    flex-direction: column;
  }

  .logo-and-title {
    & .logo {
      height: 70px;
      width: 70px;
      aspect-ratio: 1/1;
    }
  }
}

@media (max-width: 362px) {
  .certified {
    & img {
      width: 100px;
    }
  }
}
@media (max-width: 320px) {
  .certified {
    & img {
      width: 75px;
    }
  }
}

.lisa-footer {
  font-family: 'Edu AU VIC WA NT Guides', cursive;
  font-optical-sizing: auto;
  font-style: italic;
}

.footer-address {
  display: flex;
  gap: 4px;
}

@media (min-width: 462px) {
  .hide-on-large {
    display: none;
  }
}


@media (max-width: 462px) {
  .hide-on-small {
    display: none;
  }
}

.certified-banner {
  padding-top: 16px;
  text-align: center;
  & img {
    border-radius: 8px;
    max-width: 80%;
  }
  width: 100%;
  background-color: #283618;
  padding-bottom: 8px;
}
